import React from "react"
import {Link} from "gatsby";
import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import SEO from "../components/seo";

import FeaturePararaph from '../components/feature-paragraph'

import ListItem from '../components/list-item';


const Subprocessors = () => (
  <Layout>
    <SEO
    	title="Subprocessors"
    	description="Subprocessors of TimeKeeper "
    	 />
    <Navbar />
    <div className="relative py-16 bg-white overflow-hidden">
		<div className="flex flex-col items-center px-4 sm:px-6 lg:px-8">
		    <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
		      Subprocessors
		    </h1>

		</div>
		<div className="mt-12 w-full prose lg:prose-xl text-gray-600 mx-auto">
			<p><i>Last Updated: 13th August 2024</i></p>
		    <p>To provide certain functionalities of the TimeKeeper platform, we make use of third party applications and services.
		    Below we describe each of these sub processors and the purpose for which we use them. For each provider, TimeKeeper has undertaken a process to verify security and data processing compliance.
		    </p>
		    <h4>Stripe</h4>
		    <p>We use Stripe to handle the storage and billing of customer debit and credit cards for TimeKeeper subscriptions. Stripe is PCI compliant and holds the card information, we do not hold sensitive card information on our servers.</p>
		    <h4>Amazon Web Services</h4>
		    <p>Amazon Web Services is used to host and power the cloud infrastructure behind the TimeKeeper platform (website and app platform). All our services are hosted and delivered via European servers (eu-west-1).</p>
		    <h4>Functional Software, Inc. d/b/a Sentry</h4>
		    <p>Sentry is an error monitoring service that we use to track unexpected bugs and events in the platform. Sentry is a USA company.</p>
		    <h4>Crisp IM SAS</h4>
		    <p>We use Crisp for customer support and communication on the TimeKeeper web portal. Crisp is a French company.</p>
		    <h4>Klaviyo, Inc</h4>
		    <p>We use Klaviyo for marketing email communication, provided the user has consented. Klaviyo is a USA company.</p>
		    <h4>AC PM LLC “Postmark”</h4>
		    <p>Postmark is used for sending transactional email to customers about actions on their TimeKeeper account. AC PM LLC is a US company.</p>
		    <h4>Twilio</h4>
		    <p>Twilio is used for sending text messages to customers, to allow them to sign up to the platform and if they have forgot their password. Twilio is a US company.</p>
		    <h4>Google Cloud Platform</h4>
		    <p>We use Google Recaptcha to prevent spam sign ups on the registration page. We also make use of Google Maps to display locations on the TimeKeeper web and mobile applications. Finally we make use of Google Fonts to provide font files for the web platform. Google is a US company.</p>
		    <h4>Opencage GmbH</h4>
		    <p>Opencage is a reverse geocoding service. We provide a latitude and longitude coordinate pair and they supply the nearest address. Opencage is a German company.</p>
		    <h4>Sleekplan</h4>
		    <p>Sleekplan is used to host our feedback board and changelog (feedback.timekeeper.co.uk). Users can submit feature requests here. Sleekplan is a German company.</p>
		    <h4>Plausible Analytics</h4>
		    <p>Plausible Analytics is a privacy centric web analytics software used to collate analytics on visitor traffic to our marketing website.</p>
		    <h4>Better Uptime</h4>
		    <p>Better Uptime monitors the availability of our infrastructure which is shown on our status page. Better Uptime is an USA company.</p>
		    <h4>Stream.io Inc</h4>
		    <p>Stream.io Inc provides the messenger functionality within the TimeKeeper platform, this is subject to you enabling this add on in your account. Stream.io Inc is a Dutch company.</p>
			<h4>Savvycal.com</h4>
			<p>Savvycal.com is used to book calls with the TimeKeeper team. Examples include support calls or demos.</p>
		    <p>These subprocessors are subject to change over time. To be notified of subprocessor changes, please send an email to support@timekeeper.co.uk with the subject 'Subprocessor Notification'.</p>
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default Subprocessors;
